<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류 수술" tabActive="클라리베인" bg="/images/sub/surgery/visual.jpg">
                <template #title>하지정맥류 수술</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--lg font-weight-regular mb-8px mb-md-16px">클라리베인</div>
                        <div class="tit--xs primary--text text--darken-4">Clarivein™ (MOCA;Mechanochemical Ablation)</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">회전카테터를 사용하여 혈관벽에 기계적, 물리적 손상을 일으키고, 동시에 혈관경화제를 주입함으로써 화학적 손상까지 유발하여 혈관폐쇄율을 높인 수술.</u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first">
            <v-img src="/images/sub/surgery/clarivein.jpg" class="rounded" />

            <div class="pt-40px pt-md-60px">
                <v-row>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">특징 및 장점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>베나실과 함께 대표적인 ‘정맥 내 비열치료법’</li>
                                <li>열에 의한 신경손상이 없음</li>
                                <li>시술 중 통증이 적어 전신마취나 척추마취 없이 수면마취로 수술이 가능</li>
                                <li>수술 흉터가 매우 작음</li>
                                <li>퇴원 후 즉시 일상 생활 가능</li>
                                <li>
                                    미국, 유럽 및 대한민국 식약처(FDA, CE, KFDA)의 안전성 시험을 <br class="d-none d-lg-block" />
                                    정식으로 거친 후 허가된 방법
                                </li>
                                <li>화학적 작용을 위해 사용하는 약제(STS, Sodium Tetradecyl Sulfate)가 1946년부터 사용된 약제로 안정성이 검증되었음</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">단점 및 부작용</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>상대적으로 고가인 수술비</li>
                                <li>압박스타킹 2-3일 착용 (최대 1-2주)</li>
                                <li>심부정맥혈전증 (매우 희박)</li>
                                <li>일시적 정맥염 (낮은 가능성)</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>

        <page-section-primary class="page-section--xs grey lighten-5">
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-secondary">
                    <div class="tit mb-8px mb-md-16px">클라리베인(ClariVein)은</div>
                    <div class="tit--sm font-weight-regular">세계각국에서 인정받은 수술입니다.</div>
                </u-tit-default>
            </u-tit-wrap-default>
            <v-sheet class="border-y py-24px py-md-40px">
                <v-row>
                    <v-col cols="6" md="3">
                        <u-tit-default class="tit--sm font-secondary primary--text text--darken-4 text-center">1946년</u-tit-default>
                        <v-img src="/images/sub/surgery/clarivein-history-01.png" max-width="250" class="my-8px my-md-16px mx-auto" />
                        <u-txt-default class="txt--dark text-center">
                            STS(Sodium Tetradecyl <br class="d-none d-md-block" />
                            Sulfate, ClariVein 사용 약제) 사용 시작
                        </u-txt-default>
                    </v-col>
                    <v-col cols="6" md="3">
                        <u-tit-default class="tit--sm font-secondary primary--text text--darken-4 text-center">2008년</u-tit-default>
                        <v-img src="/images/sub/surgery/clarivein-history-02.png" max-width="250" class="my-8px my-md-16px mx-auto" />
                        <u-txt-default class="txt--dark text-center"> 미국 FDA 승인 </u-txt-default>
                    </v-col>
                    <v-col cols="6" md="3">
                        <u-tit-default class="tit--sm font-secondary primary--text text--darken-4 text-center">2008년</u-tit-default>
                        <v-img src="/images/sub/surgery/clarivein-history-03.png" max-width="250" class="my-8px my-md-16px mx-auto" />
                        <u-txt-default class="txt--dark text-center"> 유럽 CE(Conformité Européenne ; French) 인증 획득 </u-txt-default>
                    </v-col>
                    <v-col cols="6" md="3">
                        <u-tit-default class="tit--sm font-secondary primary--text text--darken-4 text-center">2017년</u-tit-default>
                        <v-img src="/images/sub/surgery/clarivein-history-04.png" max-width="250" class="my-8px my-md-16px mx-auto" />
                        <u-txt-default class="txt--dark text-center"> 식품의약품안전청 KFDA 승인 </u-txt-default>
                    </v-col>
                </v-row>
            </v-sheet>

            <u-tit-default class="tit--sm primary--text text--darken-4 text-center mt-16px mt-md-24px">
                우리나라에서 8년차이지만, 미국 등에서는 사용한지 이미 15년이 넘었고, <br class="d-none d-md-block" />
                주원료인 경화제는 80년 가까이 사용되어온 안전한 약제 입니다.
            </u-tit-default>
        </page-section-primary>

        <page-section-primary class="page-section--xs page-section--last">
            <v-sheet class="grey lighten-5 pa-16px pa-md-40px px-xl-80px" style="z-index: 1">
                <v-row justify="center" align="center">
                    <v-col cols="12" md="auto">
                        <v-img max-width="276" src="/images/main/professionalism-1-1.png" class="mx-auto" />
                    </v-col>
                    <v-col>
                        <u-tit-default class="tit--sm text-center text-md-start font-weight-regular line-height-17">
                            편안하지흉부외과는 <br class="d-none d-md-block" />
                            2023년 10월
                            <strong class="primary--text text--darken-4">
                                <br class="d-md-none" />
                                클라리베인 3,100례(환자기준)</strong
                            >시술을 <br class="d-none d-lg-block" />
                            ‘메드트로닉코리아’로부터 공식 확인받았습니다.
                        </u-tit-default>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet rounded class="overflow-hidden mt-40px mt-md-60px">
                <v-responsive :aspect-ratio="16 / 9">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/0zSQwJqGJEM?si=crYZSzIFZTNhXLtV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </v-responsive>
            </v-sheet>
            <v-img src="/images/sub/surgery/clarivein-report-mobile2.jpg" class="d-lg-none mt-40px" />
            <!-- <div class="mt-40px image-area d-lg-none">
                <v-img width="720" src="/images/sub/surgery/clarivein-report-mobile2.jpg" class="rounded mx-auto" v-bind="{ style }" />
            </div> -->
            <v-img src="/images/sub/surgery/clarivein-report.jpg" class="rounded d-none d-lg-block mt-md-60px" />
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    computed: {
        style() {
            let { clientWidth } = document.documentElement;
            let scale = clientWidth / 720;
            if (1 <= scale) return "";

            let mx = (720 - 720 * scale) / 2;
            let my = (1978 - 1978 * scale) / 2;
            return `transform: scale(${scale}); margin: -${my}px -${mx}px;`;
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.image-area {
    &,
    * {
        pointer-events: none !important;
    }
}
</style>

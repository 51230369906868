var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류 수술",
            "tabActive": "클라리베인",
            "bg": "/images/sub/surgery/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류 수술")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--lg font-weight-regular mb-8px mb-md-16px"
  }, [_vm._v("클라리베인")]), _c('div', {
    staticClass: "tit--xs primary--text text--darken-4"
  }, [_vm._v("Clarivein™ (MOCA;Mechanochemical Ablation)")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v("회전카테터를 사용하여 혈관벽에 기계적, 물리적 손상을 일으키고, 동시에 혈관경화제를 주입함으로써 화학적 손상까지 유발하여 혈관폐쇄율을 높인 수술.")])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first"
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/surgery/clarivein.jpg"
    }
  }), _c('div', {
    staticClass: "pt-40px pt-md-60px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("특징 및 장점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("베나실과 함께 대표적인 ‘정맥 내 비열치료법’")]), _c('li', [_vm._v("열에 의한 신경손상이 없음")]), _c('li', [_vm._v("시술 중 통증이 적어 전신마취나 척추마취 없이 수면마취로 수술이 가능")]), _c('li', [_vm._v("수술 흉터가 매우 작음")]), _c('li', [_vm._v("퇴원 후 즉시 일상 생활 가능")]), _c('li', [_vm._v(" 미국, 유럽 및 대한민국 식약처(FDA, CE, KFDA)의 안전성 시험을 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 정식으로 거친 후 허가된 방법 ")]), _c('li', [_vm._v("화학적 작용을 위해 사용하는 약제(STS, Sodium Tetradecyl Sulfate)가 1946년부터 사용된 약제로 안정성이 검증되었음")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("단점 및 부작용")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("상대적으로 고가인 수술비")]), _c('li', [_vm._v("압박스타킹 2-3일 착용 (최대 1-2주)")]), _c('li', [_vm._v("심부정맥혈전증 (매우 희박)")]), _c('li', [_vm._v("일시적 정맥염 (낮은 가능성)")])])])], 1)], 1)], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs grey lighten-5"
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit mb-8px mb-md-16px"
  }, [_vm._v("클라리베인(ClariVein)은")]), _c('div', {
    staticClass: "tit--sm font-weight-regular"
  }, [_vm._v("세계각국에서 인정받은 수술입니다.")])])], 1), _c('v-sheet', {
    staticClass: "border-y py-24px py-md-40px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-secondary primary--text text--darken-4 text-center"
  }, [_vm._v("1946년")]), _c('v-img', {
    staticClass: "my-8px my-md-16px mx-auto",
    attrs: {
      "src": "/images/sub/surgery/clarivein-history-01.png",
      "max-width": "250"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--dark text-center"
  }, [_vm._v(" STS(Sodium Tetradecyl "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" Sulfate, ClariVein 사용 약제) 사용 시작 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-secondary primary--text text--darken-4 text-center"
  }, [_vm._v("2008년")]), _c('v-img', {
    staticClass: "my-8px my-md-16px mx-auto",
    attrs: {
      "src": "/images/sub/surgery/clarivein-history-02.png",
      "max-width": "250"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--dark text-center"
  }, [_vm._v(" 미국 FDA 승인 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-secondary primary--text text--darken-4 text-center"
  }, [_vm._v("2008년")]), _c('v-img', {
    staticClass: "my-8px my-md-16px mx-auto",
    attrs: {
      "src": "/images/sub/surgery/clarivein-history-03.png",
      "max-width": "250"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--dark text-center"
  }, [_vm._v(" 유럽 CE(Conformité Européenne ; French) 인증 획득 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-secondary primary--text text--darken-4 text-center"
  }, [_vm._v("2017년")]), _c('v-img', {
    staticClass: "my-8px my-md-16px mx-auto",
    attrs: {
      "src": "/images/sub/surgery/clarivein-history-04.png",
      "max-width": "250"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--dark text-center"
  }, [_vm._v(" 식품의약품안전청 KFDA 승인 ")])], 1)], 1)], 1), _c('u-tit-default', {
    staticClass: "tit--sm primary--text text--darken-4 text-center mt-16px mt-md-24px"
  }, [_vm._v(" 우리나라에서 8년차이지만, 미국 등에서는 사용한지 이미 15년이 넘었고, "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 주원료인 경화제는 80년 가까이 사용되어온 안전한 약제 입니다. ")])], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--last"
  }, [_c('v-sheet', {
    staticClass: "grey lighten-5 pa-16px pa-md-40px px-xl-80px",
    staticStyle: {
      "z-index": "1"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "276",
      "src": "/images/main/professionalism-1-1.png"
    }
  })], 1), _c('v-col', [_c('u-tit-default', {
    staticClass: "tit--sm text-center text-md-start font-weight-regular line-height-17"
  }, [_vm._v(" 편안하지흉부외과는 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 2023년 10월 "), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_c('br', {
    staticClass: "d-md-none"
  }), _vm._v(" 클라리베인 3,100례(환자기준)")]), _vm._v("시술을 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" ‘메드트로닉코리아’로부터 공식 확인받았습니다. ")])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "overflow-hidden mt-40px mt-md-60px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_c('iframe', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "src": "https://www.youtube.com/embed/0zSQwJqGJEM?si=crYZSzIFZTNhXLtV",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  })])], 1), _c('v-img', {
    staticClass: "d-lg-none mt-40px",
    attrs: {
      "src": "/images/sub/surgery/clarivein-report-mobile2.jpg"
    }
  }), _c('v-img', {
    staticClass: "rounded d-none d-lg-block mt-md-60px",
    attrs: {
      "src": "/images/sub/surgery/clarivein-report.jpg"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }